import React from 'react';
import {Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";
import BearCognitionLogo from "../../assets/icons/bc_logo.png"

function TopNav() {
  const { logOut } = useUserAuth();
  const navigate = useNavigate();

  // handling clicking links in the navigation bar
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  
  const handleNavigateImages = () => {
    navigate("/images");
  }
  const handleNavigateHome = () => {
    navigate("/home");
  }

  const handleNavigateAnalytics = () => {
    navigate("/analytics");
  }

  return (
    <Navbar sticky="top" style={{backgroundColor: "#241f66", color: "white"}} expand="lg">
        {/* <Navbar.Brand style={{color: "white", marginLeft: "40px"}}>Bear Cognition</Navbar.Brand> */}
        <Navbar.Brand style={{marginRight: "0px"}}>
        <Nav.Link onClick={handleNavigateHome} style={{padding: "0px 20px 0px 0px"}}>
        <img style={{height: "40px", marginLeft: "40px"}} src={BearCognitionLogo} />
        </Nav.Link>
          
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor: "#e2e2e2", marginRight: "0px"}} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto my-2 my-lg-0"
          >
            {/* <Nav.Link onClick={handleNavigateHome} style={{color: "white"}}>Home</Nav.Link> */}
            <Nav.Link onClick={handleNavigateImages} style={{color: "white"}}>Documentation</Nav.Link>
            {/* <Nav.Link onClick={handleNavigateAnalytics} style={{color: "white"}}>Analytics</Nav.Link> */}
          </Nav>
          <Nav
            style={{marginRight: "40px"}}
          >
            {/* <Nav.Link onClick={handleNavigateAnalytics} style={{color: "white"}}>My Account</Nav.Link> */}
            <Nav.Link onClick={handleLogout} style={{color: "white"}}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
}

export default TopNav