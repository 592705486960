import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import TopNav from './nav/TopNav';
import DataAnalyticsGif from "../assets/images/DataAnalytics.gif"

const Home = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleNavigateImages = () => {
    navigate("/images");
  }
  return (
    <>
      <TopNav />
      
      <div style={{height: "100vh"}}>
        {/* <div className="p-4 box text-center">
          Hello Welcome <br />
          {user && user.email}
        </div> */}
        <div style={{overflow: "hidden"}}>
        <Card
        key='primary'
        text='white'
        style={{ display: "none", width: '18rem', zIndex: "20", opacity: "95%", borderRadius: "11px", margin: "30px 40px" }}
        className="mb-2 custom-card"
      >
        {/* <Card.Header>Header</Card.Header> */}
        <Card.Body>
          <Card.Title>Dashboard Images</Card.Title>
          <Card.Text>
            Upload images that can be referenced inside Perceptivity dashboards.
          </Card.Text>
          <Button variant="light" onClick={handleNavigateImages}>Go to images</Button>
        </Card.Body>
      </Card>
          <img src={DataAnalyticsGif} style={{position: "fixed", width: "100%", opacity: "30%", top: "0px", overflow: "hidden"}} />
        </div>

      </div>
      
    </>
  );
};

export default Home;