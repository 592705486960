import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";

// create .env for firebase environment variables
const firebaseConfig = {
  apiKey: "AIzaSyDaeL6yMEOOCh-zrNbjvCON5kTpPtXMVrc",
  authDomain: "bc-image-upload-and-access.firebaseapp.com",
  projectId: "bc-image-upload-and-access",
  storageBucket: "bc-image-upload-and-access.appspot.com",
  messagingSenderId: "626020386610",
  appId: "1:626020386610:web:a49c14ff390e282150b92b",
  measurementId: "G-1HFMDZ2ZMR"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
//const analytics = getAnalytics(app);
export default app;