import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import Background from "../assets/backgrounds/data-background.jpg";
import MetalBear from "../assets/images/bear-blue-metallic.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <body 
        className="login-body"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: '100% 100%'
        }}  
      >
        <div className="login-page">
          <div 
            className="login-container"
          >
            <div className="p-4 box" style={{display: "grid"}}>
              <img style={{width:"200px", margin: "0 auto"}} src={MetalBear} />
              {/* <h2 className="mb-3" style={{textAlign: "center"}}>BC Admin Login</h2> */}
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <div className="d-grid gap-2">
                  <Button variant="primary" type="Submit">
                    Log In
                  </Button>
                </div>
              </Form>
              <hr />
            </div>
            <div className="box mt-3 text-center">
              <b>Don't have an account? <Link to="/signup">Sign up</Link></b>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Login;