import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav/TopNav';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

function Analytics() {

  const [testing, setTesting] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
      // this try block pulls Image items from firestore
      
        const response = onSnapshot(collection(db, "testing"), (snapshot) => {
          const testingTemp = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
          setTesting(testingTemp);
          console.log(testingTemp);
        }, (error) => {
          //console.error("shit", error);
          setError(true);
        })
      
    }
    fetchData().then((success) => {
      console.log("success", success);
    }).catch((err) => {
      console.error("error", err);
      setError(true);
    });

    try {
      fetchData();
    } catch(error) {
      console.log("Boi we got an error");
    }
  
    
  }, []);


  //const testingRef = collection(db, "testing");
  //console.log(testingRef);
  return (
    <>
      <Navbar />
      {
        (error == false) && testing.map((item) => (
          <div key={item.id}>{item.test}</div>
        ))
      }
      {
        error && (
          <div>There was an error</div>
        )
      }
    </>
  )
}

export default Analytics