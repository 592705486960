import './styles/Images.css';
import './styles/Switch.css';
import { useState, useEffect } from "react";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { v4 } from 'uuid';
import Navbar from './components/nav/TopNav';
import EditIcon from './assets/icons/icons8-edit-64.png';
import CopyIcon from './assets/icons/icons8-copy-96.png';
import { addDoc, collection, onSnapshot, Timestamp } from "firebase/firestore";
import { db } from "./firebase";
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useUserAuth } from "./context/UserAuthContext";

function App() {
  // ------------------ STATES --------------------------------------------------------------------------------------------------------------------------
  
  // refresh key is set every time an image is uploaded.
  // refresh should occur when a CRUD operation is performed on an image
  const [refreshKey, setRefreshKey] = useState(0);

  // set title when uploading an image
  const [title, setTitle] = useState("");
  // boolean - true or false if the image has been uploaded on the main page
  const [imageUpload, setImageUpload] = useState(null);

  // set search bar when searching
  const [searchInput, setSearchInput] = useState("");

  // model for the images
  const [imagesList, setImagesList] = useState([
    {
      id: "",
      title: "",
      imageRef: "",
      imageUrl: "",
      createdBy: "",
      createdOn: ""
    }
  ]);

  // model for the filtered images (same as imagesList model)
  const [filteredImagesList, setFilteredImagesList] = useState([
    {
      id: "",
      title: "",
      imageRef: "",
      imageUrl: "",
      createdBy: "",
      createdOn: ""
    }
  ]);

  // this is when the image is selected
  const [image, setImage] = useState(null);


  //------------ MODAL STATES ------------------------------------------------------------------------------------------------------------------------------

  // for modal, we set the state of the modal and have functions for showing and closing the modal
  const [show, setShow] = useState(false);
  // set modal title when updating the name
  const [modalTitle, setModalTitle] = useState("");
  // boolean - true or false if the image has been uploaded in the modal
  const [modalImageUpload, setModalImageUpload] = useState(null);
  // click edit button
  const [editFeatures, setEditFeatures] = useState(false);

  // const [url, setUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  
  // this is when the modal image is selected
  const [modalImageForUpload, setModalImageForUpload] = useState(null);

  // toggle to edit title
  const [editTitleToggle, setEditTitleToggle] = useState(false);
  // toggle to edit the image
  const [editImageToggle, setEditImageToggle] = useState(false);

  // try to use this state to manage all the variables
  const [selectedModalImage, setSelectedModalImage] = useState(
    {
      id: "",
      title: "",
      imageRef: "",
      imageUrl: "",
      createdBy: "",
      createdOn: ""
    }
  );

  // ------------------ USE EFFECT -----------------------------------------------------------------------------------------------------------------------

  // this use effect hook runs on the load of the page, we are grabbing the urls of the images
  useEffect(() => {

    // we are clearing each of these lists for page refreshes - occurs when an image is uploaded
    setImagesList([]);

    const fetchData = async() => {
      // this try block pulls Image items from firestore
      try {
        const response = onSnapshot(collection(db, "images"), (snapshot) => {
          const imagesTemp = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
          const updateImages = [...imagesTemp];
          updateImages.sort(function(a, b) {
            let textA = a.title.toUpperCase();
            let textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA < textB) ? 1 : 0;
          })
          setImagesList(updateImages);
          setFilteredImagesList(updateImages);
          console.log("imageslist", imagesList);
        });
      } catch(err){
        console.error(err);
      }
    }
    fetchData();
  }, [refreshKey]);

  /*
    - This useEffect hook runs when letters are entered in the search input bar
    - If the input bar is empty, then no filtering is applied
    - 
  */
  useEffect(() => {
    console.log(searchInput);
    if (searchInput == null || searchInput == "") {
      setFilteredImagesList(imagesList);
      return;
    }

    // we are capitalizing the search input to make filtering work on all string properties
    const capitalizeInput = searchInput.toUpperCase();

    // The text entered in the search field is added to this filters object which will be used to filter the array of images objects
    const filters = {
      "title": [capitalizeInput]
    };

    /* 
    The filtering is how we only display images with titles that include a substring of the search input
    */
    var filterkeys = Object.keys(filters);
    var result = imagesList.slice(0);
    filterkeys.forEach(function(filterkey){
      if(filters[filterkey].length)
      {
        result = result.reduce(function(acc, value){
          filters[filterkey].some(function(filtervalue){
            return value[filterkey].toUpperCase().indexOf(filtervalue)>-1;
          }) && acc.push(value);
          console.log("acc", acc);
          setFilteredImagesList(acc);
          return acc;
        }, []);
      }
    })
  }, [searchInput]);

  //------------ FIREBASE VARIABLES  -----------------------------------------------------------------------------------------------------------------------

  // images collection ref - references the images in storage
  const imagesCollectionRef = collection(db, "images");
  // the image list ref is the location in storage where the dashboard images are stored
  const imageListRef = ref(storage, "dashboard-info/");
  // get user info from Firestore Authentication
  const { user } = useUserAuth();


  // We could add Firebase functions here


  //------------ FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------------

  // --------------- IMAGE UPLOAD ----------------------------------------------------------------------------------------------------------------------------------
  
  const loadImage = (event) => {
    setImageUpload(event);
    if(event){
      setImage(URL.createObjectURL(event));
    }
  }

  // the function when we are uploading an image to firebase
  const uploadImage = async () => {
    if(imageUpload == null) {
      alert("No image selected.");
      return;
    };
    if(title == "" || title == null){
      alert("No title selected");
      return;
    }
    let now = new Date();
    now = now.toISOString();
    let imageName = "dashboard-info/" + now + "---" + v4();
    const imageRef = ref(storage, imageName);
    await uploadBytes(imageRef, imageUpload).then(() => {
      uploadImageInformation(imageName);
      alert("Image Uploaded");
      setImageUpload(null);
      setModalImageUpload(null);
    }).catch((err) => {
      console.log(err);
    });

    const getInfo = ref(storage, imageName);
    getMetadata(getInfo).then((metadata) => {
      console.log(metadata);
    }).catch((err) => {
      console.error(err);
    })

    getDownloadURL(ref(storage, imageName)).then((url) => {
      uploadImageInformation(imageName, url);
    });

    setRefreshKey(refreshKey => refreshKey + 1);
  };

  const uploadImageInformation = async (imagePath, url) => {
    await addDoc(imagesCollectionRef, {title: title, imageRef: imagePath, createdBy: user.email, imageUrl: url, createdOn: Timestamp.fromDate(new Date()) });
  }
  
  // const getImageName = (link) => {
  //   return decodeURIComponent(link.substring(link.indexOf("/o/") + 3, link.indexOf('?')));
  // }

  //------------ MODAL FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------

  /* 
    - Logic when the modal is opened
  */
    const handleShow = (objectReference) => {
      setSelectedModalImage(objectReference.image);
      //let imageNameVar = decodeURIComponent(url.substring(url.indexOf("/o/") + 3, url.indexOf('?')));
      setShow(true);
    }
  
    /*
    - Logic when the modal is closed
    */
    const handleClose = () => {
      setShow(false);
      setModalImageUpload(null); // remove any reference of an image selected inside a modal
      setImageUpload(null); // remove any reference of an image selected on the main image page
    }
  
    // when the image in the modal is selected, we set this
    const setModalImage = (event) => {
      setModalImageUpload(event);
    }
  
    // when we are in the modal and are replacing an image
    const replaceImage = (name) => {
      if(modalImageUpload == null){
        alert("No image is selected.");
        return;
      }
      const imageRef = ref(storage, name);
      uploadBytes(imageRef, modalImageUpload).then(() => {
        alert("Image Uploaded");
        handleClose();
        setModalImageUpload(null);
        setRefreshKey(refreshKey => refreshKey + 1);
      });
    };
  
    // will need to be updated
    const deleteImage = (name) => {
      console.log(name);
      alert();
      const imageRef = ref(storage, name);
      deleteObject(imageRef).then(() => {
        alert("Image successfully deleted");
        handleClose();
        setRefreshKey(refreshKey => refreshKey + 1);
      })
    }
  
    const CopyText = (url) => {
      navigator.clipboard.writeText(url.url);
      alert("The image link is copied to the clipboard.");
    }
  
    const openLink = (link) => {
      window.open(link, '_blank').focus();
    }
  
    const editImage = () => {
      // if admin (create database with the admin)
      alert("Feature being built!");
    }
  
    // selecting a modal image
    const loadModalImage = (event) => {
      setModalImageUpload(event);
      if(event){
        setModalImageForUpload(URL.createObjectURL(event));
      }
    }

    const replaceImageDetails = async () => {
      if(editImageToggle == true && modalImageUpload == null) {
        alert("No image selected.");
        return;
      };
      if(editTitleToggle == true && (modalTitle == "" || modalTitle == null)){
        alert("No title selected");
        return;
      }

      console.log();
      
      // so we will get the same image reference in storage 
      const imageRef = ref(storage, selectedModalImage.imageRef);

      await uploadBytes(imageRef, modalImageUpload).then(() => {
        uploadImageInformation(selectedModalImage.imageRef);
        alert("Image has been replaced");
        //setModalImageUpload(null);
      }).catch((err) => {
        console.log(err);
      });
  
      //const getInfo = ref(storage, selectedModalImage.imageRef);
      // getMetadata(getInfo).then((metadata) => {
      //   console.log(metadata);
      // }).catch((err) => {
      //   console.error(err);
      // })
  
      // getDownloadURL(ref(storage, selectedModalImage.imageRef)).then((url) => {
      //   uploadImageInformation(selectedModalImage.imageRef, url);
      // });
      handleClose();
      window.location.reload();
      setRefreshKey(refreshKey => refreshKey + 1);
      
    };

    const handleEditTitleToggleChange = () => {
      setEditTitleToggle(!editTitleToggle);
      console.log(editTitleToggle);
    }

    const handleEditImageToggleChange = () => {
      setEditImageToggle(!editImageToggle);
      console.log(editImageToggle);
    }

  // --------------- PAGE COMPONENTS ------------------------------------------------------------------------------------------------------------------------

  const EditFunctionality = () => {
    return (
      <>
        <hr/>
        <div className="image-edit-function-container">
          {/* <b>Edit Image</b> */}
          {
            false && (
              <>
              
              
                <div className="change-title-container">
                  <div className="image-input-title" style={{marginBottom: "10px"}}>
                    <b>Update Title</b>
                    
                  </div>
                  <label className="switch">
                    <input type="checkbox" checked={editTitleToggle} onChange={handleEditTitleToggleChange}/>
                    <span className="slider round"></span>
                  </label>
                  {
                    !editTitleToggle && (<span> Toggle on to update the title </span>)
                  }
                  {
                    editTitleToggle && (<span> Toggle off to cancel updating the title </span>)
                  }
                  
                  {
                    editTitleToggle && (
                      <div>
                        {/* <input value={modalTitle} style={{marginBottom: "10px"}} onChange={(e) => setModalTitle(e.target.value)}></input> */}
                        <FormControl
                          value={selectedModalImage.title}
                          style={{marginTop: "12px"}}
                          placeholder="Replace title"
                          className="me-2 image-search-bar"
                          onChange={(e) => setModalTitle(e.target.value)}
                        />
                      </div>
                    )
                  }
                </div>
              
              
              </>
            )
          }
          
          <div className="replace-image-container">
            <div style={{marginBottom: "10px"}}>
              <b>Update Image</b>
            </div>
            <label className="switch">
              <input type="checkbox" checked={editImageToggle} onChange={handleEditImageToggleChange}/>
              <span className="slider round"></span>
            </label>
            {
              !editImageToggle && (<span> Toggle on to update the image </span>)
            }
            {
              editImageToggle && (<span> Toggle off to cancel updating the image </span>)
            }
            {
              editImageToggle && (
                <>
                  <div>
                    <img className="image-selected" src={modalImageForUpload} />
                  </div>
                  <div>
                    <input type="file" onChange={(event) => {loadModalImage(event.target.files[0])}}/>
                  </div>
                </>
              )
            }
            
          </div>
        </div>
        {
          (editImageToggle || editTitleToggle) && (
            <Modal.Footer>
              <div>
                <Button style={{float: "left"}} variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            
              <Button variant="primary" onClick={() => replaceImageDetails()}>
                Save Changes
              </Button>
            </Modal.Footer>
          )
        }
      </>
    )
  }

  const EditModal = (urlObject) => {
    console.log("urlObject", urlObject);
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Title: <b>{selectedModalImage.title}</b></p>
            {/* <p>Created by: <b>{selectedModalImage.createdBy}</b></p> */}
            <p style={{fontSize: "10px"}}>Image Reference: {selectedModalImage.imageRef}</p> 
            <div>
              <img className="image-modal" key={selectedModalImage.imageUrl} src={selectedModalImage.imageUrl} />
            </div>
            <Button variant="outline-primary" onClick={() => CopyText(selectedModalImage.imageUrl)}>Copy URL</Button>{' '}
            <Button variant="outline-primary" onClick={() => openLink(selectedModalImage.imageUrl)}>View Image</Button>{' '}
            {/* <Button variant="outline-danger" onClick={() => editImage()}>Edit Image</Button>{' '} */}
          </Modal.Body>
          {
            true && (
              <EditFunctionality />
            )
          }
        </Modal>
      </>
    );
  }

  // --------------- MAIN JSX ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <EditModal />
      <Navbar />
      <div className="image-file-upload-container">
        {
          imageUpload && (<h1 className="title-of-page title-selected">Image Selected</h1>) || !imageUpload && (<h2 className="title-of-page">Select Image to Upload</h2>)
        }
        <input type="file" onChange={(event) => {loadImage(event.target.files[0])}}/>
        {
          imageUpload && (
            <>
              <div>
                <div className="image-input-title"><b>Enter Title</b></div><input onChange={(e) => setTitle(e.target.value)}></input>
                <p>Note: enter title with connector + dashboard name. E.g. "Shopify Executive Overview"</p>
              </div>
              <div>
                <img className="image-selected" src={image} />
              </div>
              <div>
                <button onClick={uploadImage}>Upload Image</button>
              </div>
            </>
          )
        }
      </div>

      <div className="info-container" style={{backgroundColor: "rgb(255 244 194)"}}>
        <div><b>If you do not see any cards loading or are a new user, ask Chandler for permissions.</b></div>
      </div>
      {/* <div className="info-container">
        <div>- Images cannot currently be replaced or deleted (Feature being built)</div>
        <div>- Titles cannot currently be replaced or deleted (Feature being built)</div>
        <div>- <b>Solution:</b> If you need to replace the image, upload the image again and add "version 2" to the end of the title</div>
        <div>- Reach out to Chandler for any questions</div>
      </div> */}
      <Form className="image-search-bar-container">
        <div style={{width: "320px", display: "inline-block"}}>
          <FormControl
            style={{width: "320px"}}
            type="search"
            placeholder="Search"
            className="me-2 image-search-bar"
            aria-label="Search"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        
        <Button style={{float: "right", display: "none"}}variant="primary">Create Documentation</Button>
        {/* <Button variant="outline-primary" style={{width: "300px"}}>
          Upload Image
        </Button> */}
      </Form>
      

      <div className="img-parent">
        {filteredImagesList.map((image) => {
          const url = image.imageUrl;
          return (
            <div key={image.id} className="img-and-desc-container">
              {/* <div className="img-container">
                <img className="image-in-grid" key={image.imageUrl} src={image.imageUrl} />
              </div> */}
              <div className="img-text"><b>{image.title}</b></div>
              <hr/>
              <div className="img-text img-subtext">Created by: {image.createdBy}</div>
              {/* <div className="img-text img-subtext">Created On: {bob.createdOn.toDate().toISOString().substr(0, 10)}</div> */}
              <div className="img-icons-container">
                <img className="img-icons" onClick={() => CopyText({url})} src={CopyIcon}></img>
                <img className="img-icons" style={{float: "right"}} onClick={() => handleShow({image})} src={EditIcon}></img>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
}

export default App;
